import React from 'react';
import styled, { keyframes } from 'styled-components';
import Tooltip from 'react-tooltip';

const pulse = keyframes`
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
`;

const Point = styled.div`
  width: 18px;
  height: 18px;
  background-color: #ba2716;

  border-radius: 50%;
  position: absolute;
  left: ${({ x = 0 }) => x}%;
  top: ${({ y = 0 }) => y}%;

  &:hover {
    cursor: pointer;
  }

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #e05848;
    animation: ${pulse} 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
`;

const TriggerPoint = (props) => (
  <>
    <Point data-tip={props.tooltip} {...props} />
    <Tooltip />
  </>
);

export default TriggerPoint;
