import React from 'react';
import styled from 'styled-components';

const TextContainer = styled.a`
  position: relative;
  width: 140px;
  height: 104px;

  color: initial;
`;

const Text = styled.h1`
  font-family: DIN-BoldAlternate;
  font-size: 48px;
  line-height: 0;

  position: absolute;

  &:first-child {
    left: 28px;
  }

  &:last-child {
    top: 39px;
  }
`;

const Title = () => (
  <TextContainer href="/">
    <Text>Kiné</Text>
    <Text>Sara</Text>
  </TextContainer>
);

export default Title;
