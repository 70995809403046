import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
`;

const Container = styled.div`
  width: 80%;
  height: 100%;
  margin: auto;
  
  	
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Body = ({ children }) => (
  <Wrapper>
    <Container>{children}</Container>
  </Wrapper>
);

export default Body;
