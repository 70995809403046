import React from 'react';
import Body from './shared/Body';
import Navigation from './Navigation';

const Specialisation = () => (
  <Body>
    <Navigation />
  </Body>
);

export default Specialisation;
