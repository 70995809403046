import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './Home';
import Contact from './Contact';
import Practice from './Practice';
import Specialisation from './Specialisation';
import TriggerPoints from './TriggerPoints';
import './App.css';

const AppRouter = () => (
  <Router>
    <>
      <Route path="/" exact component={Home} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/praktijk" exact component={Practice} />
      <Route path="/specialisaties" exact component={Specialisation} />
      <Route path="/specialisaties/triggerpoints" exact component={TriggerPoints} />
    </>
  </Router>
);

export default AppRouter;
