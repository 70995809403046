import React from 'react';
import Tooltip from 'react-tooltip';
import { Link as LinkElement } from 'react-router-dom';
import styled from 'styled-components';

import './navigation.css';
import Title from './Components/Title';

const Container = styled.div`
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinksContainer = styled.div``;

const Link = styled(LinkElement)`
  font-family: DIN-BoldAlternate;
  text-decoration: none;

  font-size: 20px;

  &:visited {
    color: initial;
  }

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-right: 30px;
  }
`;

const Navigation = () => (
  <Container>
    <Title />
    <LinksContainer>
      <Link to="/">Home</Link>
      <Link to="/praktijk">Praktijk</Link>
      <Link
        effect="solid"
        data-for="custom-theme"
        data-tip="
          <ul>
            <li>
              <a href='/specialisaties/triggerpoints'>Trigger Points</Link>
            </li>
          </ul>
        "
        to="/specialisaties"
      >
        Specialisaties
      </Link>
      <Link to="/contact">Contact</Link>
    </LinksContainer>
    <Tooltip
      className={'tooltip'}
      id="custom-theme"
      effect="solid"
      place="bottom"
      delayHide={500}
      delayUpdate={250}
      html
    />
  </Container>
);

export default Navigation;
