import React from 'react';
import Body from './shared/Body';
import Navigation from './Navigation';

const Contact = () => (
  <Body>
    <Navigation />
  </Body>
);

export default Contact;
