import React from 'react';
import styled from 'styled-components';

import Body from './shared/Body';

const Temporary = styled.div`display:flex; flex-direction:column; align-items: center; justify-content: center;`;

const Text = styled.p`text-align: center;`;

const Home = () => (
  <Body>
    <Temporary>

    <Text>Momenteel onder constructie. Binnenkort is de website beschikbaar!</Text>
    <Text>Contacteer me reeds op info@kinesara.be.</Text>
    </Temporary>
  </Body>
);

export default Home;
