import React, { useState } from 'react';
import styled from 'styled-components';
import Body from './shared/Body';
import Navigation from './Navigation';
import { TriggerPoint } from './Components';

const IllustrationContainer = styled.div`
  width: 450px;
  height: 628px;
  position: relative;

  margin-top: 150px;
  flex-shrink: 0;
`;

const Illustration = styled.img`
  width: 100%;
  height: 100%;
`;

const DetailContainer = styled.div`
  margin-top: 150px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentLeftHalf = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;

const ContentRightHalf = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;

const TriggerPointTitle = styled.h1`
  font-family: 'DIN-BoldAlternate';
  font-size: 56px;
`;

const triggerPoints = [
  {
    x: 40,
    y: 6,
    tooltip: 'schouderpijn',
    title: 'schouderpijn',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in justo ante. Vivamus consequat augue tristique rutrum dignissim. Cras sollicitudin tempus mollis. Proin facilisis lacus vel lacus cursus imperdiet a id est. Sed eu congue diam. Nullam imperdiet eleifend justo sed posuere. Integer luctus mi scelerisque, vehicula leo id, maximus velit. Sed ac pellentesque elit, at luctus augue. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean vulputate libero orci, nec semper nibh rhoncus vitae. Ut auctor quam tellus, id interdum magna imperdiet ac. Quisque in ligula nec quam efficitur malesuada. Mauris malesuada congue erat, ut varius purus dictum vitae.',
  },
  {
    x: 66,
    y: 11.5,
    tooltip: 'nekpijn',
    title: 'nekpijn',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ipsum felis, facilisis rhoncus enim ut, pulvinar placerat sem. Nunc placerat turpis non nibh sollicitudin, eu porttitor massa dignissim. Phasellus non lorem a diam porttitor rhoncus sed ut erat. Praesent at ultrices turpis. Nullam commodo purus vitae sodales feugiat. Praesent tempor orci non quam egestas lacinia. Fusce non maximus odio.',
  },
  {
    x: 39,
    y: 36,
    tooltip: 'rugpijn',
    title: 'rugpijn',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus tellus nisi, ornare quis elit a, pellentesque consequat purus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Suspendisse eget ornare velit. Nullam nec feugiat felis. Aenean elit velit, interdum a ex eget, vestibulum pulvinar nunc. Praesent dignissim nunc sit amet orci dictum, sed mattis ex sagittis. Nulla sed mi consectetur, dictum turpis eget, dictum sem. Morbi sodales rutrum justo nec ultrices. Maecenas vel lorem at massa pharetra efficitur quis et leo. Aenean rhoncus metus lorem. Ut ullamcorper cursus ultrices. Curabitur quis mi vel felis rutrum semper. Mauris nec ante ac arcu vestibulum luctus et in tortor. Vestibulum est turpis, suscipit eu dolor sed, placerat dictum ex. In hac habitasse platea dictumst.',
  },
  {
    x: 44,
    y: 65,
    tooltip: 'poeppijn',
    title: 'poeppijn',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque maximus lacinia dui vitae accumsan. Ut tristique ex ut sodales efficitur. Vivamus egestas luctus efficitur. Sed vehicula turpis eu nisl finibus, ut sagittis libero auctor. Aliquam cursus tempus tortor in eleifend. Suspendisse viverra mauris lobortis nisi fringilla vestibulum. Vestibulum vel dapibus est, pretium porta leo. Sed vestibulum egestas magna, nec suscipit ante hendrerit ac. Nam nunc magna, pharetra vitae turpis nec, aliquet luctus sapien. Sed non augue egestas tortor ultricies laoreet nec a nibh. Pellentesque quis accumsan augue. Cras erat eros, porttitor feugiat orci quis, vestibulum porttitor sapien. In ultricies ante sed lorem vehicula gravida. Etiam varius dolor ligula, quis lobortis velit ultricies vitae.',
  },
];

const TriggerPoints = () => {
  const [active, setActive] = useState(0);

  const handlePointClick = (number) => {
    setActive(number);
  };

  const activeTriggerPoint = triggerPoints[active];

  return (
    <Body>
      <Navigation />
      <ContentContainer>
        <ContentLeftHalf>
          <IllustrationContainer>
            {triggerPoints.map((point, index) => (
              <TriggerPoint onClick={() => handlePointClick(index)} {...point} />
            ))}
            <Illustration src="/illustrations/triggerpoint.svg" alt="" />
          </IllustrationContainer>
        </ContentLeftHalf>
        <ContentRightHalf>
          <DetailContainer>
            <TriggerPointTitle>{activeTriggerPoint.title}</TriggerPointTitle>
            <p>{activeTriggerPoint.description}</p>
          </DetailContainer>
        </ContentRightHalf>
      </ContentContainer>
    </Body>
  );
};

export default TriggerPoints;
